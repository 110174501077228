import { __ } from '~/locale';

export const TRACKING_SAVED_REPLIES_USE = 'i_code_review_saved_replies_use';
export const TRACKING_SAVED_REPLIES_USE_IN_MR = 'i_code_review_saved_replies_use_in_mr';
export const TRACKING_SAVED_REPLIES_USE_IN_OTHER = 'i_code_review_saved_replies_use_in_other';

export const COMMENT_TEMPLATES_KEYS = ['currentUser'];
export const COMMENT_TEMPLATES_TITLES = { currentUser: __('User') };

export const RESTRICTED_TOOLBAR_ITEMS_BASIC_EDITING_ONLY = [
  'code',
  'quote',
  'bullet-list',
  'numbered-list',
  'task-list',
  'collapsible-section',
  'table',
  'attach-file',
  'full-screen',
];
