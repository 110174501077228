var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diff-file-container"},[(_vm.diffMode === _vm.$options.diffModes.replaced)?_c('div',{staticClass:"diff-viewer"},[_c('div',{staticClass:"image js-replaced-image"},[_c(_vm.imageViewComponent,_vm._b({tag:"component",scopedSlots:_vm._u([{key:"image-overlay",fn:function({ renderedWidth, renderedHeight }){return [_vm._t("image-overlay",null,{"renderedWidth":renderedWidth,"renderedHeight":renderedHeight})]}}],null,true)},'component',_vm.$props,false))],1),_vm._v(" "),_c('div',{staticClass:"view-modes"},[_c('ul',{staticClass:"view-modes-menu"},[_c('li',{class:{
            active: _vm.mode === _vm.$options.imageViewMode.twoup,
          },on:{"click":function($event){return _vm.changeMode(_vm.$options.imageViewMode.twoup)}}},[_vm._v("\n          "+_vm._s(_vm.s__('ImageDiffViewer|2-up'))+"\n        ")]),_vm._v(" "),_c('li',{class:{
            active: _vm.mode === _vm.$options.imageViewMode.swipe,
          },on:{"click":function($event){return _vm.changeMode(_vm.$options.imageViewMode.swipe)}}},[_vm._v("\n          "+_vm._s(_vm.s__('ImageDiffViewer|Swipe'))+"\n        ")]),_vm._v(" "),_c('li',{class:{
            active: _vm.mode === _vm.$options.imageViewMode.onion,
          },on:{"click":function($event){return _vm.changeMode(_vm.$options.imageViewMode.onion)}}},[_vm._v("\n          "+_vm._s(_vm.s__('ImageDiffViewer|Onion skin'))+"\n        ")])])])]):_c('div',{staticClass:"diff-viewer"},[_c('div',{staticClass:"image"},[_c('image-viewer',{attrs:{"path":_vm.imagePath,"file-size":_vm.isNew ? _vm.newSize : _vm.oldSize,"inner-css-classes":[
          'frame',
          {
            added: _vm.isNew,
            deleted: _vm.diffMode === _vm.$options.diffModes.deleted,
          },
        ]},scopedSlots:_vm._u([(_vm.isNew || _vm.isRenamed)?{key:"image-overlay",fn:function({ renderedWidth, renderedHeight }){return [_vm._t("image-overlay",null,{"renderedWidth":renderedWidth,"renderedHeight":renderedHeight})]}}:null],null,true)})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }