var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comment-warning-wrapper"},[(_vm.withAlertContainer)?_c('div',{staticClass:"error-alert",attrs:{"data-testid":"comment-field-alert-container"}}):_vm._e(),_vm._v(" "),(_vm.hasWarning)?_c('noteable-warning',{staticClass:"-gl-mb-3 gl-rounded-lg gl-rounded-bl-none gl-rounded-br-none gl-pb-5 gl-pt-4",attrs:{"is-locked":_vm.isLocked,"is-confidential":_vm.isConfidential,"noteable-type":_vm.noteableType,"locked-noteable-docs-path":_vm.noteableData.locked_discussion_docs_path,"confidential-noteable-docs-path":_vm.noteableData.confidential_issues_docs_path}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.showAttachmentWarning)?_c('attachments-warning',{class:{
      'gl-py-3': !_vm.showEmailParticipantsWarning,
      '-gl-mt-3 gl-pb-3 gl-pt-4': _vm.showEmailParticipantsWarning,
    }}):_vm._e(),_vm._v(" "),(_vm.showEmailParticipantsWarning)?_c('email-participants-warning',{staticClass:"gl-rounded-lg !gl-rounded-tl-none !gl-rounded-tr-none gl-border-t-1",class:{
      '-gl-mt-3 gl-pb-3 gl-pt-4': !_vm.showAttachmentWarning,
      'gl-mt-1 gl-py-3': _vm.showAttachmentWarning,
    },attrs:{"emails":_vm.emailParticipants}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }