var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onion-skin view"},[_c('div',{staticClass:"onion-skin-frame",style:({
      width: _vm.onionMaxPixelWidth,
      height: _vm.onionMaxPixelHeight,
      'user-select': _vm.dragging ? 'none' : null,
    })},[_c('div',{staticClass:"frame deleted",style:({
        width: _vm.onionMaxPixelWidth,
        height: _vm.onionMaxPixelHeight,
      })},[_c('image-viewer',{key:"onionOldImg",attrs:{"render-info":false,"path":_vm.oldPath},on:{"imgLoaded":_vm.onionOldImgLoaded}})],1),_vm._v(" "),_c('div',{ref:"addedFrame",staticClass:"added frame",style:({
        opacity: _vm.onionOpacity,
        width: _vm.onionMaxPixelWidth,
        height: _vm.onionMaxPixelHeight,
      })},[_c('image-viewer',{key:"onionNewImg",attrs:{"render-info":false,"path":_vm.newPath},on:{"imgLoaded":_vm.onionNewImgLoaded},scopedSlots:_vm._u([{key:"image-overlay",fn:function({ renderedWidth, renderedHeight }){return [_vm._t("image-overlay",null,{"renderedWidth":renderedWidth,"renderedHeight":renderedHeight})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"transparent"}),_vm._v(" "),_c('div',{ref:"dragTrack",staticClass:"drag-track",on:{"mousedown":_vm.startDrag,"mouseup":_vm.stopDrag,"touchstart":_vm.startDrag,"touchend":_vm.stopDrag}},[_c('div',{ref:"dragger",staticClass:"dragger",style:({ left: _vm.onionDraggerPixelPos })})]),_vm._v(" "),_c('div',{staticClass:"opaque"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }