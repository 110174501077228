/*
 * TODO: Make this URL configurable
 */
export const DRAWIO_PARAMS = {
  ui: 'sketch',
  noSaveBtn: 1,
  saveAndExit: 1,
  keepmodified: 1,
  spin: 1,
  embed: 1,
  libraries: 1,
  configure: 1,
  proto: 'json',
  toSvg: 1,
};
export const DRAWIO_FRAME_ID = 'drawio-frame';

export const DARK_BACKGROUND_COLOR = '#202020';

export const DIAGRAM_BACKGROUND_COLOR = '#ffffff';

export const DRAWIO_IFRAME_TIMEOUT = 4000;

export const DIAGRAM_MAX_SIZE = 10 * 1024 * 1024; // 1MB
