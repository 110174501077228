import commentForm from '~/notes/components/comment_form.vue';
import NoteableNote from '~/notes/components/noteable_note.vue';
import NoteableDiscussion from '~/notes/components/noteable_discussion.vue';
import { s__ } from '~/locale';
import { createAlert } from '~/alert';

const I18N_ILLEGAL_CHARACTERS_TIPS_WITH_APPEAL_EMAIL = s__(
  "JH|ContentValidation|Your content couldn't be submitted because it violated the rules. If you believe this was a miscalculation, please email usersupport@gitlab.cn to appeal. We will process your appeal within 24 hours (working days) and send the result to your registered email address, please pay attention to it. Thank you for your understanding and support.",
);

const handleContentValidationError = (originHandler, component, err) => {
  if (err.response?.data?.content_invalid) {
    createAlert({
      message: I18N_ILLEGAL_CHARACTERS_TIPS_WITH_APPEAL_EMAIL,
      parent: component ? component.$el : document,
    });
  } else {
    originHandler.call(component, err);
  }
};

const { handleSaveError } = commentForm.methods;
commentForm.methods.handleSaveError = function handleSaveErrorInJH({ data, status }) {
  if (data?.content_invalid) {
    this.errors = [I18N_ILLEGAL_CHARACTERS_TIPS_WITH_APPEAL_EMAIL];
  } else {
    handleSaveError.call(this, { data, status });
  }
};

const { handleUpdateError } = NoteableNote.methods;
NoteableNote.methods.handleUpdateError = function handleUpdateErrorInJH(err) {
  handleContentValidationError(handleUpdateError, this, err);
};

const { handleSaveError: handleDiscussionSaveError } = NoteableDiscussion.methods;
NoteableDiscussion.methods.handleSaveError = function handleSaveErrorInJH(err) {
  handleContentValidationError(handleDiscussionSaveError, this, err);
};
