import Tracking from '~/tracking/tracking';

export { Tracking as default };

/**
 * Initializes posthog tracking.
 * @returns {undefined}
 */
export function initUserTracking() {
  if (!Tracking.posthogEnabled()) {
    return;
  }

  window.posthog.init(window.gon.posthog_api_key, { api_host: window.gon.posthog_api_host });

  Tracking.flushPendingEvents();
}

/**
 * Enables tracking of built-in events: page views, page pings.
 * Optionally enables form and link tracking (automatically).
 * Attaches event handlers for data-attributes powered events, and
 * load-events (on render).
 *
 * @returns {undefined}
 */
export function initDefaultTrackers() {
  if (!Tracking.posthogEnabled()) {
    return;
  }

  Tracking.bindDocument();
  Tracking.trackLoadEvents();
  // https://posthog.com/docs/integrate/identifying-users
  // Identifying the current user.
  if (window.gon?.current_user_id && window.gon?.current_user_fullname) {
    window.posthog.identify(window.gon?.current_user_id, {
      // TODO: update the name of this field
      PERSON: window.gon?.current_user_fullname,
    });
  }
}
