var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swipe view"},[_c('div',{ref:"swipeFrame",staticClass:"swipe-frame",style:({
      width: _vm.swipeMaxPixelWidth,
      height: _vm.swipeMaxPixelHeight,
      'user-select': _vm.dragging ? 'none' : null,
    })},[_c('image-viewer',{key:"swipeOldImg",ref:"swipeOldImg",staticClass:"frame deleted",attrs:{"render-info":false,"path":_vm.oldPath},on:{"imgLoaded":_vm.swipeOldImgLoaded}}),_vm._v(" "),_c('div',{ref:"swipeWrap",staticClass:"swipe-wrap",style:({
        width: `${_vm.swipeWrapWidth}%`,
      })},[_c('image-viewer',{key:"swipeNewImg",staticClass:"frame added",style:({
          width: _vm.swipeMaxPixelWidth,
        }),attrs:{"render-info":false,"path":_vm.newPath},on:{"imgLoaded":_vm.swipeNewImgLoaded},scopedSlots:_vm._u([{key:"image-overlay",fn:function({ renderedWidth, renderedHeight }){return [_vm._t("image-overlay",null,{"renderedWidth":renderedWidth,"renderedHeight":renderedHeight})]}}],null,true)})],1),_vm._v(" "),_c('span',{ref:"swipeBar",staticClass:"swipe-bar",style:({ left: _vm.swipeBarPixelPos }),on:{"mousedown":_vm.startDrag,"mouseup":_vm.stopDrag,"touchstart":_vm.startDrag,"touchend":_vm.stopDrag}},[_c('span',{staticClass:"top-handle"}),_vm._v(" "),_c('span',{staticClass:"bottom-handle"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }